/* eslint-disable import/no-default-export,max-len */
import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/Layout';
import { styled } from '../styles/theme';

const WrappedText = styled.div`
  p {
    color: black;
    font-size: 16px;
  }
  ol > li {
    margin-bottom: 20px;
  }
  ul > li {
    margin-top: 10px;
  }
  ul {
    list-style-type: lower-alpha;
  }
  ul > li:last-of-type {
    margin-bottom: 10px;
  }
`;

const NestedList = styled.ul`
  padding-left: 30px;
`;

export default () => (
  <>
    <Helmet title="Polityka prywatności | swiatubezpieczen.com" />
    <Layout>
      <section id="hero" className="section section--about-us-hero-image" style={{ padding: '60px' }}>
        <div className="wrapper">
          <div className="wrapper" />
        </div>
      </section>
      <section id="birth" className="section section--lightblue">
        <div className="wrapper">
          <div className="row row--col-reverse row--flex">
            <WrappedText className="row__large-12x">
              <h1><strong>POLITYKA PRYWATNOŚCI I KORZYSTANIA Z PLIKÓW COOKIES</strong></h1>
              <h2><strong>1. UŻYTE W NINIEJSZYM DOKUMENCIE DEFINICJE:</strong></h2>
              <ol>
                <li>
                  <p>
                    <strong>Strona internetowa</strong>
                    {' '}
                    – strona internetowa
                    {' '}
                    <a href="/">www.swiatubezpieczen.com</a>
                    , której operatorem jest Porówneo.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Porówneo / Administrator</strong>
                    {' '}
                    - spółka pod firmą Porowneo.pl Sp. z o.o. z siedzibą w Warszawie (00-189) przy ul. Inflanckiej 4B, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XII Wydział Gospodarczy KRS pod numerem: 0000439594, NIP: 5213640335, REGON: 146398104, o kapitale zakładowym: 5.000 PLN; numer telefonu +48 529 902 560, adres e-mail
                    {' '}
                    <a href="mailto:klient@porowneo.pl">klient@porowneo.pl</a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Użytkownik</strong>
                    {' '}
                    – podmiot korzystający ze Strony internetowej.
                  </p>
                </li>

              </ol>
              <h2><strong>2. INFORMACJE OGÓLNE DOTYCZĄCE PRZETWARZANIA DANYCH OSOBOWYCH PRZEZ PORÓWNEO:</strong></h2>
              <ol>
                <li>
                  <p>Administratorem danych osobowych Użytkowników jest Porówneo.</p>
                </li>
                <li>
                  <p>Porówneo wyznaczyło inspektora ochrony danych Użytkowników, z którym można się skontaktować w następujący sposób:</p>
                  <NestedList>
                    <li>
                      {' '}
                      <p>Pisemnie na adres: Porowneo.pl Sp. z o.o. ul. Inflancka 4B, 00-189 Warszawa</p>
                    </li>
                    <li>
                      {' '}
                      <p>Elektronicznie na adres: ado@porowneo.pl</p>
                    </li>
                  </NestedList>
                </li>
                <li>
                  <p>W przypadku nawiązania kontaktu przez Użytkownika z Porówneo przez e-mail lub za pomocą poczty tradycyjnej, podane przez Użytkownika dane (np. imię, nazwisko, adres, podany adres e-mail) będą zapisywane, aby umożliwić udzielenie informacji na zgłoszone zapytanie. Uzyskane w ten sposób dane są usuwane, kiedy ich przechowywanie nie będzie już konieczne lub ich przetwarzanie zostanie ograniczone, jeżeli istnieje ustawowy obowiązek ich przechowywania.</p>
                </li>
                <li>
                  <p>Cele, podstawy prawne oraz okres przetwarzania danych osobowych wskazane są odrębnie w stosunku do każdego celu przetwarzania danych. Szczegółowe informacje na ten temat Użytkownik może znaleźć w kolejnych punktach niniejszej Polityki Prywatności.</p>
                </li>
              </ol>
              <h2>
                <strong>3. PRZYSŁUGUJĄCE UŻYTKOWNIKOM UPRAWNIENIA ZWIĄZANE Z OCHRONĄ DANYCH OSOBOWYCH</strong>
              </h2>
              <ol>

                <li>
                  <p>W odniesieniu do Państwa danych osobowych przysługują Państwu następujące prawa wobec Administratora:</p>
                  <NestedList>
                    <li>
                      <p>prawo do informacji,</p>
                    </li>
                    <li><p>prawo do poprawiania lub usuwania,</p></li>
                    <li><p>prawo do ograniczenia przetwarzania,</p></li>
                    <li><p>prawo do sprzeciwu wobec przetwarzania danych osobowych,</p></li>
                    <li>
                      <p>prawo do przenoszenia danych</p>
                    </li>
                  </NestedList>
                </li>

                <li>
                  <p>Ponadto każdy Użytkownik ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych w związku z przetwarzaniem przez Porówneo jego danych osobowych.</p>
                </li>
                <li>
                  <p>Zgodnie z przepisami Porówneo jest uprawnione do odmówienia realizacji niektórych uprawnień, jeżeli realizacja danego uprawnienia stałaby w sprzeczności z uzasadnionym celem przetwarzania przez Administratora określonych danych.</p>
                </li>
              </ol>
              <h2><strong>4. ZBIERANIE DANYCH PODCZAS WIZYTY NA STRONIE INTERNETOWEJ</strong></h2>
              <ol>
                <li>
                  <p>W przypadku gdy Strona internetowa jest wykorzystywana przez Użytkownika jedynie w celu uzyskania informacji, to znaczy, jeśli Użytkownik nie przekazuje danych osobowych w inny sposób, zbierane są tylko te dane osobowe, które są przekazywane na serwer Administratora przez przeglądarkę internetową Użytkownika. Szczegółowe informacje na ten temat opisane są w poniższych punktach Polityki Prywatności.</p>
                </li>
                <li>
                  <p>Podczas przeglądania Strony internetowej, zbierane są dane potrzebne ze względów technicznych, aby móc wyświetlić Stronę internetową oraz zagwarantować jej stabilność i bezpieczeństwo (podstawę prawną stanowi art. 6 ust. 1 z. 1 lit. f RODO, czyli przetwarzanie odbywa się na podstawie prawnie uzasadnionego interesu). Są to następujące informacje:</p>
                  <NestedList>
                    <li>
                      <p>adres IP</p>
                    </li>
                    <li><p>data i godzina zapytania</p></li>
                    <li><p>różnica strefy czasowej w stosunku do Greenwich Mean Time (GMT)</p></li>
                    <li><p>treść żądania (konkretna strona)</p></li>
                    <li><p>status dostępu/ kod odpowiedzi http</p></li>
                    <li><p>przesyłana ilość danych</p></li>
                    <li><p>strona internetowa, z której pochodzi żądanie</p></li>
                    <li><p>przeglądarka</p></li>
                    <li><p>system operacyjny i jego interfejs</p></li>
                    <li><p>język i wersja oprogramowania przeglądarki.</p></li>
                  </NestedList>
                  <p>Powyższe dane nie są kojarzone z konkretnymi osobami korzystającymi ze Strony internetowej i nie są wykorzystywane w celu identyfikacji Użytkownika.</p>
                </li>
                <li><p>Dodatkowo, oprócz wyżej wymienionych danych, podczas korzystania ze Strony internetowej na komputerze Użytkownika zapisywane są pliki cookies. Pliki cookies to niewielkie pliki tekstowe, zapisywane w pamięci komputera i przyporządkowane do wykorzystywanej przez Użytkownika przeglądarki. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.</p></li>
                <li><p>Pliki cookies nie mogą uruchamiać żadnych programów ani przenosić wirusów na komputer Użytkownika. Dzięki nim oferta internetowa jest bardziej przyjazna i efektywna dla jej użytkowników.</p></li>
                <li><p>Można wyróżnić różne rodzaje plików cookies. Strona internetowa wykorzystuje cookies własne wykorzystywane w celu zapewnienia prawidłowego jej działania oraz cookies pochodzące od podmiotów trzecich, a także cookies sesyjne (przy których po zakończeniu sesji danej przeglądarki lub wyłączeniu komputera zapisane informacje są usuwane z pamięci urządzenia), jak i pliki cookies trwałe (które są przechowywane w pamięci urządzenia końcowego użytkownika i pozostają tam do momentu ich skasowania lub wygaśnięcia).</p></li>
                <li><p>Podczas pierwszej wizyty na Stronie internetowej wyświetlana jest Użytkownikom informacja na temat stosowania plików cookies. Dzięki specjalnemu narzędziu Użytkownicy mają możliwość zarządzania plikami cookies z poziomu strony. W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies oraz mechanizmów śledzących w urządzeniu końcowym Użytkownika. Ustawienia cookies mogą zostać zmienione z poziomu wykorzystywanej przez Użytkownika przeglądarki, a także w całości usunięte. Przeglądarki zarządzają ustawieniami cookies na różne sposoby. W menu pomocniczym przeglądarki internetowej Użytkownik może znaleźć wyjaśnienia dotyczące zmiany jego ustawień cookies.</p></li>
                <li>
                  <p>Podstawą do wykorzystywania plików cookies jest wyrażona przez Użytkownika zgoda, którą w dowolnym momencie można cofnąć blokując korzystanie z cookies i mechanizmów śledzących. Zgodnie z obowiązującymi przepisami zgoda ta może zostać również wyrażona za pomocą wspomnianych powyżej ustawień przeglądarki internetowej. Wyłączenie lub ograniczenie obsługi plików cookies może powodować trudności w korzystaniu ze Strony internetowej, jak również z wielu innych stron internetowych, które je stosują. Przetwarzanie danych Użytkownika jest dobrowolne i będzie miało miejsce do czasu wycofania zgody lub do czasu ustania celu przetwarzania, wskazanego w niniejszej Polityce Prywatności.</p>
                </li>
              </ol>
              <h2><strong>5. WYKORZYSTYWANIE PLIKÓW COOKIES I INNYCH TECHNOLOGII ŚLEDZĄCYCH:</strong></h2>
              <ol>
                <li>
                  <p>Pliki cookies wykorzystywane są w celu:</p>
                  <NestedList>
                    <li>
                      <p>Dostosowania zawartości stron internetowych do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych,</p>
                    </li>
                    <li><p>Tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;</p></li>
                    <li><p>Analizy zachowania Użytkownika w celu dostarczania mu spersonalizowanych informacji marketingowych.</p></li>
                  </NestedList>
                  <p>Dla powyższych celów Porówneo korzysta również z narzędzi udostępnianych przez podmioty trzecie. Informacje w tym zakresie opisane są poniżej.</p>
                </li>

                <li>
                  <p>
                    <strong>Google Analytics.</strong>
                    {' '}
                    Porówneo informuje, że wykorzystuje pliki cookies do śledzenia statystyk strony, takich jak liczba osób odwiedzających, rodzaj systemu operacyjnego i przeglądarki internetowej wykorzystywanej do przeglądania strony, czas spędzony na stronie czy odwiedzone podstrony. W tym celu korzysta z narzędzia Google Analytics, co wiąże się z wykorzystywaniem plików cookies przez firmę Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Działania te realizowane są w oparciu o prawnie uzasadniony interes polegający na tworzeniu statystyk i ich analizy w celu optymalizacji Strony internetowej.
                  </p>
                </li>
                <li>
                  <p>
                    Użytkownik może zapobiec rejestrowaniu zgromadzonych przez pliki cookies danych dotyczących korzystania ze Strony internetowej przez Google, jak również przetwarzaniu tych danych przez Google, instalując wtyczkę do przeglądarki znajdującą się pod następującym adresem:
                    <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                    . W ramach Google Analytics zbierane są również dane demograficzne oraz dane o zainteresowaniach. W ramach ustawień plików cookies Użytkownik może zdecydować, czy wyraża zgodę na ich zbieranie czy też nie. Jeżeli Użytkownik jest zainteresowany szczegółami związanymi z przetwarzaniem danych w ramach Google Analytics, Porówneo zachęca do zapoznania się z wyjaśnieniami przygotowanymi przez spółkę Google LLC:
                    <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a>
                    .
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Google Tag Manager.</strong>
                    {' '}
                    Porówneo informuje także o korzystaniu z narzędzia Google Tag Manager udostępnianego również przez firmę Google LLC. Przy okazji wejścia na Stronę internetową automatycznie pozostawiany jest w urządzeniu Użytkownika plik cookie firmy Google, który z pomocą pseudonimowego identyfikatora (ID) oraz na podstawie odwiedzanych przez Użytkownika stron umożliwia wyświetlanie reklam opartych na zainteresowaniach, kontrolowanie efektywności tych reklam oraz inne działania związane z obserwacją zachowania Użytkownika na stronie. Działania w tym zakresie również oparte są na prawnie uzasadnionym interesie w postaci marketingu własnych produktów lub usług oraz optymalizacji stron internetowych. Dalsze przetwarzanie danych Użytkownika ma miejsce tylko wtedy, gdy Użytkownik wyraził zgodę wobec Google na łączenie historii przeglądania i użycia aplikacji z kontem Użytkownika w domenie Google oraz wykorzystanie informacji z konta Google do personalizowania reklam, które wyświetlane są na stronach internetowych. Jeśli Użytkownik będzie zalogowany podczas wizyty na Stronie internetowej w serwisie Google, Google wykorzysta dane Użytkownika razem z danymi Google Analytics w celu stworzenia i zdefiniowania list grup docelowych dla celów remarketingu na różnych urządzeniach. W tym celu Google łączy tymczasowo dane osobowe Użytkownika z danymi Google Analytics, aby utworzyć grupy docelowe.
                  </p>
                </li>
                <li>
                  <p>
                    Użytkownik może dezaktywować cookies wykorzystywane do remarketingu w ramach swoich ustawień konta Google:
                    <a href="https://adssettings.google.com/">https://adssettings.google.com</a>
                    . Użytkownicy zainteresowani przetwarzaniem danych w ramach Google Tag Manager mogą zapoznać się z polityką prywatności Google:
                    <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    .
                  </p>
                </li>
                <li><p> osobowe przetwarzane są na terenie Unii Europejskiej oraz w Zjednoczonym Królestwie Wielkiej Brytanii i Irlandii Północnej. Operacje przetwarzania Danych Osobowych z udziałem podmiotu mającego siedzibę poza terenem Europejskiego Obszaru Gospodarczego (EOG) następować będą w zgodzie z zasadami wynikającymi z Rozdziału V RODO, w tym poprzez zawarcie z takim podmiotem stosownych klauzul umownych zapewniających bezpieczeństwo danych (standardowe klauzule umowne ochrony danych osobowych) bądź w oparciu o właściwą decyzję Komisji Europejskiej o uznaniu danego kraju, w którym siedzibę ma taki podmiot, za zapewniający odpowiedni poziom zabezpieczenia danych osobowych.</p></li>
              </ol>
            </WrappedText>
          </div>
        </div>
      </section>
    </Layout>
  </>
);
